<template>
  <div>
    <v-layout class="project-card">
      <v-flex xs12 sm6 offset-sm3>
        <v-card raised>
          <v-img
            :src="this.project_image_url"
            aspect-ratio="2.75"
          ></v-img>
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">{{ project_name }}</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="project-card-body">{{ project_description }}
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn v-if="project_links.length > 1" v-bind:key="project_name" text color="info"
                   v-on:click="viewProjectURLS"
            >View Project Links
            </v-btn>
            <v-btn v-else v-for="link in project_links" v-bind:key="link.project_url" text color="info"
                   :href="link.project_url">{{ link.project_label }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline primary lighten-2"
          primary-title
        >
          Project Links
        </v-card-title>

        <v-card-text>
          <br>
          <v-btn
            v-for="link in project_links"
            v-bind:key="link.project_url"
            :href="link.project_url"
            class="mx-3 white--text"
            icon
          >
            <v-icon size="24px">{{ link.link_icon }}</v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'ProjectCard',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    project_image_url: null,
    project_name: null,
    project_description: null,
    project_links: {}
  },
  methods: {
    viewProjectURLS () {
      this.dialog = true
    }
  }
}
</script>
<style scoped>

@media only screen and (max-width: 1100px) {

}

.project-card {
  margin-bottom: 5vh;
}

</style>
