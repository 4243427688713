<template>
  <div id="home">
    <header class="bg-image">
      <div class="parallax-blur"></div>
      <h1 class="header-text">Euan Riggans</h1>
      <h4 class="sub-header-text">Software / Web Developer</h4>
    </header>
    <h1 class="text-center projects-header">My Projects / Previous Work</h1>
    <SkeletonCard v-if="projects.length <= 0"/>
    <ProjectCard v-else v-for="project in projects" v-bind:key="project.human_id"
                 :project_image_url="project.project_image_url" :project_description="project.project_description"
                 :project_name="project.project_name" :project_links="project.project_links"/>
    <SocialLinksFooter/>
  </div>
</template>

<script>
import db from '../config/firebaseInit'
import SkeletonCard from '../components/SkeletonCard'
import ProjectCard from '../components/ProjectCard'
import SocialLinksFooter from '../components/SocialLinksFooter'

export default {
  name: 'home',
  components: { SocialLinksFooter, ProjectCard, SkeletonCard },
  data () {
    return {
      projects: [],
      display_project_links: []
    }
  },
  created () {
    db
      .collection('projects')
      .orderBy('edited', 'desc')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = {
            project_name: doc.data().name,
            project_description: doc.data().project_description,
            project_image_url: doc.data().project_image_url,
            project_links: doc.data().project_links,
            approved: doc.data().approved
          }
          if (data.approved) {
            this.projects.push(data)
          }
        })
      })
  },
  props: {
    source: String
  }
}
</script>

<style scoped>
  .header-text {
    font-size: 15vh;
    margin-top: 35vh;
  }

  .sub-header-text {
    font-size: 4vh;
  }

  @media only screen and (max-width: 1100px) {
    .header-text {
      font-size: 7vh;
      margin-top: 35vh;
    }

    .sub-header-text {
      font-size: 4vh;
    }
  }

  .bg-image {
    display: block;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 100vh;
    background: #333 no-repeat fixed top center;
    background-size: cover;
    margin-bottom: 5vh;
  }

  .bg-image:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #333 url("../assets/euanriggans-com-background.jpg") no-repeat fixed top center;
    background-size: cover;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: .8;
  }

  .projects-header {
    margin-bottom: 5vh;
    font-size: 6vh;
  }

  .projects-loading {
    margin-top: 100px;
    margin-bottom: 150px;
  }

</style>
