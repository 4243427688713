<template>
  <div id="contact">
    <v-layout class="project-card mt-12 mb-12">
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-card-title>
            <span class="headline">Contact Me</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form id="contact-form" action="https://formspree.io/xzbjrjrq" method="POST">
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Your Name" name="_username" v-model="contact_name" filled
                                  required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Your email" type="email" name="_replyto"
                                  v-model="contact_email" filled
                                  required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea label="Your Message" name="message" v-model="contact_message" filled
                                required></v-textarea>
                  </v-col>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text to="/">Home</v-btn>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" v-on:click="submitForm">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <SocialLinksFooter/>
  </div>
</template>

<script>
import SocialLinksFooter from '../components/SocialLinksFooter'

export default {
  name: 'contact',
  components: { SocialLinksFooter },
  data () {
    return {
      contact_name: '',
      contact_email: '',
      contact_message: '',
    }
  },
  methods: {
    submitForm () {
      document.getElementById('contact-form').submit()
    }
  },
  props: {
    source: String
  }
}
</script>

<style scoped>

</style>
