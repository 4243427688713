<template>
  <v-footer
    class="pa-0"
  >
    <v-card
      flat
      tile
      dark
      width="100%"
      class="text-center"
    >
      <v-card-text>
        <div v-if="social_links.length <= 0" class="text-center social-link-loading">
          <v-progress-circular
            :size="70"
            :width="7"
            color="blue"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-btn
          v-else
          v-for="link in social_links"
          v-bind:key="link.social_link_url"
          class="mx-3 white--text"
          icon
          :href="link.social_link_url"
        >
          <v-icon size="24px">{{link.social_link_icon}}</v-icon>
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Euan Riggans</strong> -
        <router-link to="/contact">Get in touch with me</router-link>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
import db from '../config/firebaseInit'

export default {
  name: 'SocialLinksFooter',
  data () {
    return {
      social_links: []
    }
  },
  created () {
    db
      .collection('social_links')
      .orderBy('edited', 'desc')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = {
            social_link_name: doc.data().name,
            social_link_url: doc.data().social_link_url,
            social_link_icon: doc.data().social_link_icon,
            approved: doc.data().approved
          }
          if (data.approved) {
            this.social_links.push(data)
          }
        })
      })
  }
}
</script>
<style scoped>

  @media only screen and (max-width: 1100px) {

  }

  .social-link-loading {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
