<template>
  <v-layout class="project-card">
    <v-flex xs12 sm6 offset-sm3>
      <v-card raised loading>
        <v-img
          src="https://static.euanriggans.com/file/static-euanriggans-com/euanriggans-com/img/projects/projects-loading.png"
          aspect-ratio="2.75"
        ></v-img>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">Projects Loading</h3>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="project-card-body">Projects are being loaded, please wait a second...
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text color="primary">loading...</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: 'SkeletonCard'
}
</script>
<style scoped>

  @media only screen and (max-width: 1100px) {

  }

  .project-card {
    margin-bottom: 5vh;
  }

</style>
